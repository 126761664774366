import axios, { AxiosError, AxiosRequestHeaders, Method } from 'axios';
import axiosRetry from 'axios-retry';
import { Md5 } from 'ts-md5';

import { WRONG_BID_RATE } from '../config/constants';
import { tt } from '../config/i18n';
import { IRequestCallbacks } from '../domain/services/common';
import { getAuthHeader, logout } from '../helpers/authHeader';
import { showMessage } from '../helpers/notifications';
import { API_URL, PUBLIC_API_URL } from '../helpers/settings';
import { AxiosErrorHandlers } from './axiosErrorHandlers';
import { isCsv, isJson } from './axiosHelpers';

interface IGeneralRequestData {
  [index: string]: any;
}

interface IGeneralRequestParams {
  [index: string]: any;
}

export const OLD_SWAGGER_ERROR_REGEXP = /^'(.*)' (.*) - '(.*)'/;
export const SWAGGER_ERROR_REGEXP =
  /^(?<currValue>('.*')|(\d*)) (?<signature>.*) (?<awaited>('.*')|(.*))? - '(?<field>.*)'/;
export const SWAGGER_ERROR_SIGNATURE_INDEX = 2;
export const SWAGGER_ERROR_FIELD_INDEX = 3;

const errorDetailsOverrides = {
  '/lots/': { 'Wrong rate': WRONG_BID_RATE },
};

interface IGeneralRequestConfig<T> extends IRequestCallbacks<T> {
  url: string;
  method?: Method;
  data?: IGeneralRequestData;
  params?: IGeneralRequestParams;
  headers?: 'none' | AxiosRequestHeaders;
  apiVersion?: '1' | '2';
  handledNatively?: boolean;
}

type ISecretRefreshSubscribersCallback = (secret: string) => void;

let Secret;
let isRefreshingSecret = false;
let banned = false;
let refreshSubscribers: ISecretRefreshSubscribersCallback[] = [];
const ignoredErrorCodes = [412, 401, 429];
const ignoredRetryCodes = [400, 403];
const ignoredDetails = ['No such deal', 'currently not available'];

const client = axios.create({});

client.interceptors.request.use((config) => {
  if (banned) throw new axios.Cancel('Banned');
  return config;
});

export const mainErrorHandler = (error: AxiosError<any>) => {
  const swaggerErrorsMatch = error?.response?.data?.detail.match(SWAGGER_ERROR_REGEXP);
  let regularError = error.response?.data?.detail || error?.response?.statusText || '';

  let override = '';

  Object.keys(errorDetailsOverrides).map((key) => {
    if (
      error.request.responseURL.includes(key) &&
      Object.keys(errorDetailsOverrides[key]).includes(regularError)
    )
      override = errorDetailsOverrides[key][regularError];
  });

  if (regularError === 'impossible') {
    regularError = tt('errors', 'impossible');
  }

  let swaggerError = '';

  if (
    swaggerErrorsMatch &&
    swaggerErrorsMatch.length &&
    swaggerErrorsMatch.groups &&
    Object.keys(swaggerErrorsMatch.groups).length === 4
  ) {
    swaggerError += `${tt('errors.fields', swaggerErrorsMatch.groups.field)} `;

    const isAwaitedPresent = !Number.isNaN(
      Number(
        swaggerErrorsMatch.groups.awaited?.[0] === "'"
          ? swaggerErrorsMatch.groups.awaited.slice(1, -1)
          : swaggerErrorsMatch.groups.awaited,
      ),
    );

    swaggerError += `${tt(
      'errors',
      `${swaggerErrorsMatch?.groups.signature}${
        isAwaitedPresent ? '' : ` ${swaggerErrorsMatch?.groups.awaited?.trim()}`
      }`,
    )} ${
      isAwaitedPresent
        ? tt(
            'errors.fields',
            swaggerErrorsMatch?.groups?.awaited,
            swaggerErrorsMatch?.groups?.awaited,
          )
        : ''
    }
    `;
  }

  console.log(swaggerErrorsMatch?.length ? swaggerError : override || regularError);

  if (
    !ignoredDetails.includes(error?.response?.data?.detail) &&
    !ignoredErrorCodes.includes(error?.response?.status ?? 0)
  ) {
    showMessage(
      'error',
      swaggerErrorsMatch?.length ? swaggerError : override || regularError,
    );
  }
};

client.interceptors.response.use(
  (response) => response,
  (error) => {
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;
    if (status === 412) {
      if (!isRefreshingSecret) {
        isRefreshingSecret = true;
        getCodeData()
          .then(handleCodedata)
          .catch(() => (isRefreshingSecret = false));
      }

      return new Promise((resolve) => {
        subscribeSecretRefresh((secret) => {
          originalRequest.headers['AuthKey'] = secret;
          resolve(axios(originalRequest));
        });
      });
    } else {
      return Promise.reject(error);
    }
  },
);

export const handleCodedata = (codeData: Record<string, unknown>) => {
  isRefreshingSecret = false;
  const needReverse = codeData['aKM'] === 'e';
  const md5 = new Md5();
  let codedataKeys = Object.keys(codeData).sort();

  needReverse && (codedataKeys = codedataKeys.reverse());

  const codedataValues = codedataKeys.map((key) => codeData[key]);
  const result = codedataValues.join('') + 'l';
  Secret = md5.appendStr(result).end() as string;
  onSecretRefreshed(Secret);
  refreshSubscribers = [];
};

const subscribeSecretRefresh = (cb: ISecretRefreshSubscribersCallback) => {
  refreshSubscribers.push(cb);
};

const onSecretRefreshed = (secret: string) => {
  refreshSubscribers.map((cb: ISecretRefreshSubscribersCallback) => cb(secret));
};

axiosRetry(client, {
  retries: 3,
  retryDelay: (retryCount) => retryCount * 1000,
  retryCondition: (error: any) => {
    AxiosErrorHandlers[`${error.response?.status}`]
      ? AxiosErrorHandlers[`${error.response?.status}`](error)
      : AxiosErrorHandlers.default(error);

    if (error.response?.data.detail === 'User is baned') banned = true;

    return error.response
      ? !ignoredErrorCodes.includes(error.response.status) &&
          !ignoredRetryCodes.includes(error.response.status)
      : axiosRetry.isRetryableError(error);
  },
});

export const getCodeData = (): Promise<any> => generalRequest({ url: '/codedata' });

export const generalRequest = async <T>(config: IGeneralRequestConfig<T>): Promise<T> => {
  const { successCallback, errorCallback, apiVersion = '1' } = config;
  let { headers = getAuthHeader() } = config;

  if (headers === 'none') headers = {};

  Secret && (headers['AuthKey'] = Secret);

  if (!Object.prototype.hasOwnProperty.call(headers, 'Content-Type')) {
    headers['Content-Type'] = 'application/json;charset=utf-8';
  }
  if (headers['Content-Type'] === '') delete headers['Content-Type'];
  headers['Access-Control-Allow-Origin'] = '*';

  const onSuccess = (response) => {
    const { data } = response;

    let formedData;

    if (isCsv(response)) formedData = new Blob([data]);
    else if (isJson(response)) formedData = data;
    else formedData = JSON.stringify(data);

    successCallback?.(formedData);
    return formedData;
  };

  const onError = async (error: AxiosError<any>) => {
    !errorCallback && mainErrorHandler(error);
    errorCallback?.(error);

    return Promise.reject(error.response);
  };

  if (config.handledNatively)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return client({
      baseURL: `${apiVersion === '1' ? API_URL : PUBLIC_API_URL}/rest/v${apiVersion}`,
      url: config.url,
      method: config.method ?? 'GET',
      params: config.params,
      data: config.data,
      headers: headers,
    });

  return client({
    baseURL: `${apiVersion === '1' ? API_URL : PUBLIC_API_URL}/rest/v${apiVersion}`,
    url: config.url,
    method: config.method ?? 'GET',
    params: config.params,
    data: config.data,
    headers: headers,
  })
    .then(onSuccess)
    .catch(onError);
};
